import { 
    createRouter, 
    createWebHashHistory 
} from "vue-router";

import HomePage from '../components/HomePage';
import TimelinePage from '../components/TimelinePage';


export default createRouter({
    history: createWebHashHistory(),
    routes: [{
        path: '/',
        name: 'Home',
        component: HomePage,
    },{
        path: '/timeline',
        name: 'Timeline',
        component: TimelinePage,
    }],
});