<template>
  <div class="container">
     <!-- Timeline -->

    <!-- Section: Timeline -->
    <div class="container py-5">
      <div class="container">
        <hr class="my-5" />
        <h4 class="md-5"><strong>Timeline</strong></h4>
        <hr class="my-5" />
      </div>
      <div class="main-timeline-4 text-white">
        <div class="timeline-4 left-4">
          <div class="card gradient-custom">
            <div class="card-body p-4">
              <i class="fas fa-brain fa-2x mb-3"></i>
              <h4>August 2024</h4>
              <p class="small text-white-50 mb-4">Newsletter Initiative</p>
              <div class="spinner-grow" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>
        <div class="timeline-4 right-4">
          <div class="card gradient-custom-4">
            <div class="card-body p-4">
              <i class="fas fa-camera fa-2x mb-3"></i>
              <h4>22 June 2023</h4>
              <p class="small text-white-50 mb-4">Demo</p>
              <p>
                We have done this presentation earlier, but was not able to progress further due to complexities and workload.
              </p>
              <div class="ratio ratio-16x9">
                <iframe
                  src="https://www.youtube.com/embed/yUhS5PG6Q4E"
                  title="YouTube video"
                  allowfullscreen
                >
                </iframe>
              </div>
            </div>
          </div>
        </div>
        <div class="timeline-4 left-4">
          <div class="card gradient-custom">
            <div class="card-body p-4">
              <i class="fas fa-eye fa-2x mb-3"></i>
              <h4>19 May 2023</h4>
              <p class="small text-white-50 mb-4">Game Title</p>
              <p>
                ACE: Academy of Combat and Evolutions
              </p>
              <img src="https://cdn.simplexitydynamics.com/images/ace/ace-logo.png" class="img-fluid img-small" />
            </div>
          </div>
        </div>
        <div class="timeline-4 right-4">
          <div class="card gradient-custom-4">
            <div class="card-body p-4">
              <!-- <i class="fas fa-gem fa-2x mb-3"></i> -->
              <i class="fas fa-wand-magic-sparkles fa-xl mb-3"></i>
              <h4>7 March 2023</h4>
              <p class="small text-white-50 mb-4">Prototypes</p>
              <p>
                Testing environment design and character controls.
              </p>
              <a href="https://l.facebook.com/l.php?u=https%3A%2F%2Fwww.instagram.com%2Fs%2FaGlnaGxpZ2h0OjE3OTI5NDQ1MDkzNTQwNDgy%3Fstory_media_id%3D3052852352125412332_47306212318%26igshid%3DMTc4MmM1YmI2Ng%253D%253D%26fbclid%3DIwAR14nV5vIllBCYReUze-MlXTPUrgJIyglLsYiE2X-VC9hmTZtziukH7vyOY&h=AT1R48vFE7ygDs0B43zozGo4n9Yb5isRnhA7HVu8o4F9l5xeIpqePMjRPMYkEQVeW8d3uBD3Zy9C9lSQKekTE8VOSafPrJs-cLJW6Nm5yML8l0XA0E2IxrfhVuOsTI1fVYXmbBP_MCg">
                <i class="fa-brands fa-instagram fa-beat-fade fa-2xl"></i>
              </a>
            </div>
          </div>
        </div>
        <div class="timeline-4 left-4">
          <div class="card gradient-custom">
            <div class="card-body p-4">
              <i class="fas fa-palette fa-2x mb-3"></i>
              <h4>21 July 2022</h4>
              <p class="small text-white-50 mb-4">Process Outline</p>
              <p>
                We are new with game development process, and will need few iterations before we get comfortable. <br/>
                <br/>
                At this time, our major concerns are on animations and effects. <br/>
                <br/>
                We are also building our IT and software development capabilities together with the studio setup.
              </p>
              <img src="https://cdn.simplexitydynamics.com/images/process-0.1.png" class="img-fluid" />
            </div>
          </div>
        </div>
        <div class="timeline-4 right-4">
          <div class="card gradient-custom-4">
            <div class="card-body p-4">
              <i class="fas fa-laugh-beam fa-2x mb-3"></i>
              <h4>19 July 2022</h4>
              <p class="small text-white-50 mb-4">
                Our first game concept.
              </p>
              <br />

              <h5>Project Artemis</h5>
              <p class="small text-white-50 mb-4">Foundations</p>
              <p>
                As the name suggest, our goal is to build strong female characters. Our inspiration for this are Street Fighter, Mortal Kombat, mecha models, kawaii anime, traditional Greek and Roman gods and goddesses.
              </p>
              <br />
              <h5>Project Zeus</h5>
              <p class="small text-white-50 mb-4">Nuts and Bolts</p>
              <p>
                The next phase of our character design.
              </p>
            </div>
          </div>
        </div>
        <div class="timeline-4 left-4">
          <div class="card gradient-custom">
            <div class="card-body p-4">
              <i class="fas fa-sun fa-2x mb-3"></i>
              <h4>27 June 2022</h4>
              <p class="small text-white-50 mb-4">Company Registration</p>
              <p>
                Simplexity Dynamics officially registered as New Zealand Limited company.  Hutt Studio is one of the company's trading name.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Section: Timeline -->

    <!-- End of Timeline -->




  </div>

</template>
  
<script>
export default {
  name: 'HomePage',
  props: {
    msg: String
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #2d1855;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4 {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: #bbb;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-4 {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-4::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -11px;
  background-color: #bbb;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left-4 {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right-4 {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-4::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid rgba(37, 117, 252, 1);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgba(37, 117, 252, 1);
}

/* Add arrows to the right container (pointing left) */
.right-4::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid rgba(245, 87, 108, 1);
  border-width: 10px 10px 10px 0;
  border-color: transparent rgba(245, 87, 108, 1) transparent transparent;
}

.img-small {
    max-width: 50%
}

/* Fix the circle for containers on the right side */
.right-4::after {
  left: -14px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .main-timeline-4::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-4 {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-4::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left-4::after,
  .right-4::after {
    left: 18px;
  }

  .left-4::before {
    right: auto;
  }

  /* Make all right containers behave like the left ones */
  .right-4 {
    left: 0%;
  }
}

.gradient-custom {
  /* fallback for old browsers */
  background: #6a11cb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );
}

.gradient-custom-4 {
  /* fallback for old browsers */
  background: #f093fb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to left,
    rgba(240, 147, 251, 1),
    rgba(245, 87, 108, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to left,
    rgba(240, 147, 251, 1),
    rgba(245, 87, 108, 1)
  );
}
</style>