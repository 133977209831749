<template>

<!--Main Navigation-->
<header>
  <!-- Navbar -->
  <!-- Navbar -->
<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <!-- Container wrapper -->
  <div class="container-fluid">
    <!-- Toggle button -->
    <button
      class="navbar-toggler"
      type="button"
      data-mdb-toggle="collapse"
      data-mdb-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>

    <!-- Collapsible wrapper -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Navbar brand -->
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item active">
            <router-link class="navbar-brand mt-2 mt-sm-0" to="/">
              <img
                src="https://cdn.simplexitydynamics.com/icons/hutts/favicon-32x32.png"
                height="32"
                alt="Hutt Studio"
                loading="lazy"
              />
            </router-link>
          </li>
          <li class="nav-item">
            <router-link class="navbar-brand mt-2 mt-sm-0" to="/">
             Hutt Studio
            </router-link>
          </li>
        </ul>
     </div>
    <!-- Collapsible wrapper -->

    <!-- Right elements -->
    <div class="d-flex align-items-center">
      <!-- Icon -->
      <span class="me-3">
        <router-link class="nav-link" to="/">
          <i class="fas fa-home"></i>
          Home
        </router-link> 
      </span>
      
      <span class="me-3">
        <router-link class="nav-link" to="/timeline">
          <i class="fas fa-timeline"></i>
          Timeline
        </router-link>
      </span>

      <span class="me-3">
        <a class="nav-link" href="https://ace.huttstudio.com/" target="_blank" rel="noreferrer noopener">
          <i class="fa-solid fa-gear fa-spin"></i>
          ACE
        </a>
      </span>
      
    </div>
    <!-- Right elements -->
  </div>
  <!-- Container wrapper -->
</nav>
<!-- Navbar -->
  <!-- Navbar -->

  <!-- Jumbotron -->
  <!-- <div class="p-5 text-center bg-light">
    <img src="https://cdn.simplexitydynamics.com/icons/hutts/chrome-192x192.png" class="img-fluid" /> 
    <h1 class="mb-3">Hutt Studio </h1>
    <h4 class="mb-3">Something we 
      <br/>l<i class="fa-regular fa-heart fa-beat-fade" style="color: #b00721;"></i>ve 
      <i class="fa-solid fa-plus fa-2xs fa-flip" style="color: #85a5db;"></i> 
      awes<i class="fa-regular fa-face-laugh-beam fa-bounce" style="color: #2c60ba;"></i>me 
      <br/>in the works!</h4>
    
  </div> -->
  <!-- Jumbotron -->
</header>
  <!--Main Navigation-->





</template>

<script>
export default {
  name: 'NavTop',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #2d1855;
}
#intro {
  /* Margin to fix overlapping fixed navbar */
  margin-top: 58px;
}

@media (max-width: 991px) {
  #intro {
    /* Margin to fix overlapping fixed navbar */
    margin-top: 45px;
  }
}

</style>
