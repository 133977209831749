<template>
  <div class="container">
      <!-- Jumbotron -->
      <!-- <div class="p-5 text-center bg-light">
        <img src="https://cdn.simplexitydynamics.com/icons/hutts/chrome-192x192.png" class="img-fluid" /> 
        <h1 class="mb-3">Hutt Studio </h1>
        <h4 class="mb-3">Something we 
          <br/>l<i class="fa-regular fa-heart fa-beat-fade" style="color: #b00721;"></i>ve 
          <i class="fa-solid fa-plus fa-2xs fa-flip" style="color: #85a5db;"></i> 
          awes<i class="fa-regular fa-face-laugh-beam fa-bounce" style="color: #2c60ba;"></i>me 
          <br/>in the works!</h4>
        
      </div> -->
      <!-- Jumbotron -->

    <!-- video intro -->
    <div >
      <div id="intro" class="ratio ratio-16x9">
        <video style="min-width: 100%; min-height: 100%;" playsinline autoplay muted loop>
          <source class="h-100" src="https://cdn.simplexitydynamics.com/vids/movie_016.mp4" type="video/mp4" />
        </video>
        <div class="mask-tn-1">
          <div class="container d-flex align-items-center justify-content-center text-center h-100">
            <div class="text-white">
              <h1 class="mb-3">Hutt Studio</h1>
              <h6 class="mb-4">
                Game development in progress, pre-alpha: ACE.
              </h6>
              <img src="https://cdn.simplexitydynamics.com/images/ace/ace-logo.png" class="img-fluid float-end img-small rounded-7" />
            </div>
          </div>
        </div>
      </div>
    </div>
      

    <!-- / video intro -->

    <hr class="my-5" />

    <!-- <div>
      <P v-if="isAndroid">is android:  {{ isAndroidDevice }} </P>
      <P v-if="deviceType">P Device Test : {{ deviceType }}</P><br/>
      <production-list v-if="deviceType">Prod List Device Test : {{ deviceType }}</production-list><br/>
      <production-list-mobile v-if="deviceType">Prod List Mobile Device Test : {{ deviceType }}</production-list-mobile>
    </div> -->

    <!-- <hr class="my-5" /> -->
    <div>
      <h1>Kickstarter Launch Checklist</h1>
      <br/>  
    </div>


    <div> 
      <div class="list-group">
        <a class="list-group-item list-group-item-action text-dark" >
          <div class="d-flex w-100 justify-content-between">
            <h5 >Early Supporters Buildup</h5>
            <small>Work in Progress</small>
          </div>
          <div>
            <p class="mb-1">Newsletter Registration</p>
            <p class="mb-1">Hutt Studio Sign-up / Login</p>
            <p class="mb-1">Newsletters and Updates</p>
            <p class="mb-1">Campaign Materials:  How Supporters Can Help</p>
          </div>
          <div class="d-flex w-100 justify-content-between">
            <h5 >&nbsp;</h5>
          </div>
        </a>
        <a class="list-group-item list-group-item-action text-dark" >
          <div class="d-flex w-100 justify-content-between">
            <h5 >Target Products</h5>
            <small>Pending</small>
          </div>
          <div>
            <p class="mb-1">Word Games</p>
            <p class="mb-1">Number Games</p>
            <p class="mb-1">From Low Poly, Indie to Triple-A Games</p>
            <p class="mb-1">Merchandise</p>
          </div>
          <div class="d-flex w-100 justify-content-between">
            <h5 >&nbsp;</h5>
          </div>
        </a>
        <a class="list-group-item list-group-item-action text-dark" >
          <div class="d-flex w-100 justify-content-between">
            <h5 >Target Rewards</h5>
            <small>Pending</small>
          </div>
          <div>
            <p class="mb-1">Game Tokens</p>
            <p class="mb-1">Digital Products</p>
          </div>
          <div class="d-flex w-100 justify-content-between">
            <h5 >&nbsp;</h5>
          </div>
        </a>
        <a class="list-group-item list-group-item-action text-dark" >
          <div class="d-flex w-100 justify-content-between">
            <h5 >Story</h5>
            <small>Work in Progress</small>
          </div>
          <div>
            <p class="mb-1">Risk and Challenges</p>
            <p class="mb-1">Knowledge and Skills Buildup</p>
            <p class="mb-1">Development and Production Buidup</p>
          </div>
          <div class="d-flex w-100 justify-content-between">
            <h5 >&nbsp;</h5>
          </div>
        </a>
      </div>
    </div>

    <hr class="my-5" />

    <div>
      <h5 class="mb-4">About the Team</h5>
      <p>
        Powered by the imaginations of the founding members, we are building towards a goal of game entertainment that are of value, not only for us but also the community.  
        This we do through putting in the time, effort and passion for the development of our future products, services, and games. 
      </p>
    </div>
    
    
    <hr class="my-5" />

  </div>

</template>
  
<script>
export default {
  name: 'HomePage',
  props: {
    msg: String,
    // deviceType: String,
    // isAndroidDevice: String,
  },

  // mounted() {
  //     this.setDeviceType();
  //     this.isAndroid();
  // },
  // updated() {
  //   this.setDeviceType();
  // },

  // methods: {
    // setDeviceType() {
    //       //const platform = navigator.platform.toLowerCase();
    //       const platform = navigator.userAgent.toLowerCase();
    //       if (/(webos|blackberry|windows phone)/i.test(platform)) {
    //           this.deviceType = 'mobile';
    //       } else if (/(android)/i.test(platform)) {
    //           this.deviceType = 'android';    
    //       } else if (/(iphone|ipad|ipod)/i.test(platform)) {
    //           this.deviceType = 'ios';    
    //       } else if (/mac|win|linux/i.test(platform)) {
    //           this.deviceType = 'desktop';
    //       } else if (/tablet|ipad/i.test(platform)) {
    //           this.deviceType = 'tablet';
    //       } else {
    //           this.deviceType = 'unknown';
    //       }
    //     },
    // },

    
}


</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4 {
  position: relative;
}

/* The actual timeline (the vertical ruler) */
.main-timeline-4::after {
  content: "";
  position: absolute;
  width: 3px;
  background-color: #bbb;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px;
}

/* Container around content */
.timeline-4 {
  position: relative;
  background-color: inherit;
  width: 50%;
}

/* The circles on the timeline */
.timeline-4::after {
  content: "";
  position: absolute;
  width: 25px;
  height: 25px;
  right: -11px;
  background-color: #bbb;
  top: 15px;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left-4 {
  padding: 0px 40px 20px 0px;
  left: 0;
}

/* Place the container to the right */
.right-4 {
  padding: 0px 0px 20px 40px;
  left: 50%;
}

/* Add arrows to the left container (pointing right) */
.left-4::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  right: 30px;
  border: medium solid rgba(37, 117, 252, 1);
  border-width: 10px 0 10px 10px;
  border-color: transparent transparent transparent rgba(37, 117, 252, 1);
}

/* Add arrows to the right container (pointing left) */
.right-4::before {
  content: " ";
  position: absolute;
  top: 18px;
  z-index: 1;
  left: 30px;
  border: medium solid rgba(245, 87, 108, 1);
  border-width: 10px 10px 10px 0;
  border-color: transparent rgba(245, 87, 108, 1) transparent transparent;
}

.img-small {
    max-width: 25%
}

.img-lg {
    max-width: 75%
}


/* Fix the circle for containers on the right side */
.right-4::after {
  left: -14px;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {
  /* Place the timelime to the left */
  .main-timeline-4::after {
    left: 31px;
  }

  /* Full-width containers */
  .timeline-4 {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .timeline-4::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left-4::after,
  .right-4::after {
    left: 18px;
  }

  .left-4::before {
    right: auto;
  }

  /* Make all right containers behave like the left ones */
  .right-4 {
    left: 0%;
  }
}

.gradient-custom {
  /* fallback for old browsers */
  background: #6a11cb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to right,
    rgba(106, 17, 203, 1),
    rgba(37, 117, 252, 1)
  );
}

.gradient-custom-4 {
  /* fallback for old browsers */
  background: #f093fb;

  /* Chrome 10-25, Safari 5.1-6 */
  background: -webkit-linear-gradient(
    to left,
    rgba(240, 147, 251, 1),
    rgba(245, 87, 108, 1)
  );

  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: linear-gradient(
    to left,
    rgba(240, 147, 251, 1),
    rgba(245, 87, 108, 1)
  );
}
</style>